import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";

import {connect, useDispatch} from "react-redux";

//SimpleBar
import SimpleBar from "simplebar-react";

import {Link} from "react-router-dom";

import "./rightbar.css";
//Import images

import {withTranslation} from "react-i18next";
import {
    showRightSidebarCourierAction,
} from "../../store/layout/actions";
import {notification} from 'antd';
import {courirerNull, createCourier, getCouriersFlights, getCouriersParcels} from "../../store/courier/actions";

import ObjectColumns from "./ObjectColumns";

const modalWarning = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მონიშნეთ გრაფები',
    });
};
const modalWarning2 = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'აუცილებელია შეავსოთ ყველა მოცემული ველი სწორად.',
    });
};

const RightbarCourier = props => {
    const dispatch = useDispatch();
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')
    const [sq1, setsq1] = useState(false)
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [disabledInput, setdisabledInput] = useState(true)
    const [selectProducts, setSelectProducts] = useState([])

    const [phoneErr, setPhoneErr] = useState(false)
    const [nameErr, setNameErr] = useState(false)
    const [addressErr, setAddressErr] = useState(false)
    const bstable = useRef([])

    const [branches, setBranches] = useState('')

    useEffect(() => {
        if (branches.length > 1) {
            let item = {
                branches: branches
            }
            dispatch(getCouriersFlights(item))
            setPhone(props.Auth.candidate.phone)
            setName(props.Auth.candidate.information.nameKa + ' ' + props.Auth.candidate.information.lastNameKa)
        }
    }, [branches])


    useEffect(() => {
        setBranches('')
        dispatch(courirerNull())
    }, [])


    /*    useEffect(() => {
            dispatch(getCouriersFlights())
            setPhone(props.Auth.candidate.phone)
            setName(props.Auth.candidate.information.nameKa + ' ' + props.Auth.candidate.information.lastNameKa)
        }, [])*/

    useEffect(() => {
        if (sq1) {
            setdisabledInput(false)
            setName('')
            setPhone('')
        } else {
            setdisabledInput(true)
            setName(props.Auth.candidate.information.nameKa + ' ' + props.Auth.candidate.information.lastNameKa)
            setPhone(props.Auth.candidate.phone)
        }
    }, [sq1])

    const handleCloseForm = () => {
        dispatch(showRightSidebarCourierAction(false))
    }
    const [uniqArray, setUniqArray] = useState([])

    const onSelectAll = (isSelected) => {
        if (isSelected) {
            props.Courier.array.map((item) => {
                setSelectProducts(prevArray =>
                    [...prevArray, item]
                )
            })
        } else {
            setSelectProducts([])
        }
    }

    function onRowSelect(row, isSelected) {
        if (isSelected) {
            setSelectProducts(prevArray =>
                [...prevArray, row]
            )
        } else {
            setSelectProducts(selectProducts.filter(item => item !== row))
        }
    }

    const handleCreateCourierService = () => {
        let uniqueSet = new Set(selectProducts)
        let uniArray = [...uniqueSet]
        setUniqArray(uniqArray)

        if (uniArray.length === 0) {
            modalWarning('error');
        } else {

            if (city && address && name && phone && branches) {
                let item = {
                    ids: uniArray,
                    sq1,
                    city,
                    address,
                    name,
                    phone,
                    branches
                }
                bstable.current.selectionContext.selected = [];
                setSelectProducts([])

                dispatch(createCourier(item))
            } else {

                modalWarning2('error');
                if (!name) {
                    setNameErr(true)
                } else {
                    setNameErr(false)
                }
                if (!phone) {
                    setPhoneErr(true)
                } else {
                    setPhoneErr(false)
                }
                if (!address) {
                    setAddressErr(true)
                } else {
                    setAddressErr(false)
                }
            }


            //  setSelectProducts([])
            // dispatch(changeStatusStep2Cheked(item))
        }
    }

    return (
        <React.Fragment>
            <div className="right-bar">
                <SimpleBar style={{height: "900px"}}>
                    <div className="h-100">
                        <div className="rightbar-title d-flex align-items-center px-3 py-4">
                            <h5 className="m-0 me-2" style={{paddingLeft: '10px'}}>{props.t("_DELIVERY_SERVICE_")}</h5>
                            <Link
                                to="#"
                                onClick={handleCloseForm}
                                className="right-bar-toggle ms-auto"
                            >
                                <i className="mdi mdi-close noti-icon"/>
                            </Link>
                        </div>

                        <hr className="my-0"/>

                        <div className="p-4">
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Row>
                                            <Col md={12}>
                                                <h4>{props.t("_Choose_a_branch")}</h4>
                                                <div className="mb-3">
                                                    <Label className="form-label"
                                                           htmlFor="formrow-rePassword-input"></Label>
                                                    <select
                                                        value={branches}
                                                        onChange={(e) => setBranches(e.target.value)}
                                                        className="form-control form-control-border">
                                                        <option value="">{props.t('_Choose_a_branch')}</option>
                                                        <option value="didube">{props.t('_Branch_Address_1')}</option>
                                                        <option value="gldani">{props.t('_Branch_Address_2')}</option>
                                                        <option value="isani">{props.t('_Branch_Address_3')}</option>
                                                        <option value="kutaisi_1">{props.t('kutaisi_1')}</option>
                                                    </select>
                                                </div>
                                            </Col>

                                            <Col md={12}>


                                                {
                                                    props.Courier.array.length > 0
                                                        ?
                                                        <ObjectColumns
                                                            bstable={bstable}
                                                            onRowSelect={onRowSelect}
                                                            onSelectAll={onSelectAll}
                                                            flights={props.Courier.array}
                                                        />

                                                        : null
                                                }


                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                            </Row>
                            <hr className="my-0"/>
                            <h4>{props.t('_COURIER_RECEIVER_INFORMATION_')}</h4>
                            <Col md={12}>
                                <FormGroup>
                                    <div className="form-check">
                                        <Input
                                            checked={sq1}
                                            onChange={() => {
                                                setsq1(!sq1)
                                            }}
                                            type="checkbox" className="form-check-input" id="formrow-customCheck"/>
                                        <Label className="form-check-label" htmlFor="formrow-customCheck">
                                            {props.t("_COURIER_WILL_BE_TAKEN_OTHER_BY_THIRD_PARTY_")}
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Row>
                                <Col md={12}>
                                    <label
                                        className='bolder-label'>   {props.t("_CITY_")} </label>
                                    <select
                                        onChange={(e) => setCity(e.target.value)}
                                        className="form-control form-control-border">
                                        <option value="">{props.t("_SELECT_CITY_")}</option>
                                        <option value="_TBILISI_">{props.t("_TBILISI_")}</option>
                                        <option value="_KUTAISI_">{props.t("_KUTAISI_")}</option>
                                        <option value="_BATUMI_">{props.t("_BATUMI_")}</option>
                                        <option value="_KOBULETI_">{props.t("_KOBULETI_")}</option>
                                        <option
                                            value="_ABASTUMANI_">{props.t("_ABASTUMANI_")}</option>
                                        <option value="_ABASHA_">{props.t("_ABASHA_")}</option>
                                        <option value="_ADIGENI_">{props.t("_ADIGENI_")}</option>
                                        <option
                                            value="_AMBROLAURI_">{props.t("_AMBROLAURI_")}</option>
                                        <option value="_ANAKLIA_">{props.t("_ANAKLIA_")}</option>
                                        <option value="_ASPINDZA_">{props.t("_ASPINDZA_")}</option>
                                        <option
                                            value="_AKHALGORI_">{props.t("_AKHALGORI_")}</option>
                                        <option
                                            value="_AKHALKALAKI_">{props.t("_AKHALKALAKI_")}</option>
                                        <option
                                            value="_AKHALTSIKHE_">{props.t("_AKHALTSIKHE_")}</option>
                                        <option value="_AKHMETA_">{props.t("_AKHMETA_")}</option>
                                        <option
                                            value="_BAKURIANI_">{props.t("_BAKURIANI_")}</option>
                                        <option value="_BAXMARO_">{props.t("_BAXMARO_")}</option>
                                        <option value="_BARDADI_">{props.t("_BARDADI_")}</option>
                                        <option value="_BOLNISI_">{props.t("_BOLNISI_")}</option>
                                        <option value="_BORJOMI_">{props.t("_BORJOMI_")}</option>
                                        <option value="_CAGVERI_">{props.t("_CAGVERI_")}</option>
                                        <option
                                            value="_GARDABANI_">{props.t("_GARDABANI_")}</option>
                                        <option value="_GONIO_">{props.t("_GONIO_")}</option>
                                        <option value="_GORI_">{props.t("_GORI_")}</option>
                                        <option
                                            value="_GRIGOLETI_">{props.t("_GRIGOLETI_")}</option>
                                        <option value="_GUDAURI_">{props.t("_GUDAURI_")}</option>
                                        <option value="_GURJAANI_">{props.t("_GURJAANI_")}</option>
                                        <option
                                            value="_DEDOFLISCKARO_">{props.t("_DEDOFLISCKARO_")}</option>
                                        <option value="_DMANISI_">{props.t("_DMANISI_")}</option>
                                        <option value="_DUSETI_">{props.t("_DUSETI_")}</option>
                                        <option
                                            value="_ZESTAFONI_">{props.t("_ZESTAFONI_")}</option>
                                        <option value="_ZUGDIDI_">{props.t("_ZUGDIDI_")}</option>
                                        <option value="_VANI_">{props.t("_VANI_")}</option>
                                        <option
                                            value="_TETRIWYARO_">{props.t("_TETRIWYARO_")}</option>
                                        <option value="_TELAVI_">{props.t("_TELAVI_")}</option>
                                        <option value="_TERJOLA_">{props.t("_TERJOLA_")}</option>
                                        <option value="_TIANETI_">{props.t("_TIANETI_")}</option>
                                        <option value="_KASPI_">{props.t("_KASPI_")}</option>
                                        <option value="_KAJRETI_">{props.t("_KAJRETI_")}</option>
                                        <option value="_KVARIATI_">{props.t("_KVARIATI_")}</option>
                                        <option value="_KOJORI_">{props.t("_KOJORI_")}</option>
                                        <option value="_LAGODEXI_">{props.t("_LAGODEXI_")}</option>
                                        <option value="_LANCXUTI_">{props.t("_LANCXUTI_")}</option>
                                        <option value="_MARNEULI_">{props.t("_MARNEULI_")}</option>
                                        <option value="_LENTEXI_">{props.t("_LENTEXI_")}</option>
                                        <option value="_MARTVILI_">{props.t("_MARTVILI_")}</option>
                                        <option value="_MCXETA_">{props.t("_MCXETA_")}</option>
                                        <option value="_MESTIA_">{props.t("_MESTIA_")}</option>
                                        <option
                                            value="_MCVANEKONCXI_">{props.t("_MCVANEKONCXI_")}</option>
                                        <option
                                            value="_NINOWMINDA_">{props.t("_NINOWMINDA_")}</option>
                                        <option value="_OZURGETI_">{props.t("_OZURGETI_")}</option>
                                        <option value="_ONI_">{props.t("_ONI_")}</option>
                                        <option value="_RUSTAVI_">{props.t("_RUSTAVI_")}</option>
                                        <option value="_SAGAREJO_">{props.t("_SAGAREJO_")}</option>
                                        <option value="_SAGURAMO_">{props.t("_SAGURAMO_")}</option>
                                        <option value="_SAIRME_">{props.t("_SAIRME_")}</option>
                                        <option
                                            value="_SAMTREDIA_">{props.t("_SAMTREDIA_")}</option>
                                        <option value="_SARFI_">{props.t("_SARFI_")}</option>
                                        <option value="_SACXERE_">{props.t("_SACXERE_")}</option>
                                        <option value="_SENAKI_">{props.t("_SENAKI_")}</option>
                                        <option value="_SIRNARI_">{props.t("_SIRNARI_")}</option>
                                        <option
                                            value="_STEFANWMIN_">{props.t("_STEFANWMIN_")}</option>
                                        <option value="_SURAMI_">{props.t("_SURAMI_")}</option>
                                        <option
                                            value="_TABAXMELA_">{props.t("_TABAXMELA_")}</option>
                                        <option value="_TYIBULI_">{props.t("_TYIBULI_")}</option>
                                        <option value="_UREKI_">{props.t("_UREKI_")}</option>
                                        <option value="_FOTI_">{props.t("_FOTI_")}</option>
                                        <option value="_QARELI_">{props.t("_QARELI_")}</option>
                                        <option value="_QEDA_">{props.t("_QEDA_")}</option>
                                        <option value="_YVARELI_">{props.t("_YVARELI_")}</option>
                                        <option value="_SUAXEVI_">{props.t("_SUAXEVI_")}</option>
                                        <option value="_CHAQVI_">{props.t("_CHAQVI_")}</option>
                                        <option
                                            value="_COXATAURI_">{props.t("_COXATAURI_")}</option>
                                        <option value="_CAGERI_">{props.t("_CAGERI_")}</option>
                                        <option value="_CXOROWYU_">{props.t("_CXOROWYU_")}</option>
                                        <option value="_WAVKISI_">{props.t("_WAVKISI_")}</option>
                                        <option
                                            value="_WALENJIXA_">{props.t("_WALENJIXA_")}</option>
                                        <option value="_WALKA_">{props.t("_WALKA_")}</option>
                                        <option
                                            value="_TSEROVANI_">{props.t("_TSEROVANI_")}</option>
                                        <option value="_WYALTUBO_">{props.t("_WYALTUBO_")}</option>
                                        <option value="_WNORI_">{props.t("_WNORI_")}</option>
                                        <option value="_WYNETI_">{props.t("_WYNETI_")}</option>
                                        <option value="_WIATURA_">{props.t("_WIATURA_")}</option>
                                        <option
                                            value="_XARAGAULI_">{props.t("_XARAGAULI_")}</option>
                                        <option value="_XASHURI_">{props.t("_XASHURI_")}</option>
                                        <option
                                            value="_XELVACAURI_">{props.t("_XELVACAURI_")}</option>
                                        <option value="_XOBI_">{props.t("_XOBI_")}</option>
                                        <option value="_XONI_">{props.t("_XONI_")}</option>
                                        <option value="_XULO_">{props.t("_XULO_")}</option>
                                    </select>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Label className="form-label"
                                           htmlFor="formrow-nameKa-input">{props.t("_ADDRESS_")} </Label>
                                    <Input
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        style={addressErr ? {borderColor: 'red'} : null}
                                        // onChange={(e) => setAddress(e.target.value)}
                                        type="text" className="form-control" id="formrow-nameKa-input"/>
                                    <i style={{color: 'red'}}>შეიყვანეთ მისამართი ქართულად</i>
                                </Col>
                                <Col md={12}>
                                    <Label className="form-label"
                                           htmlFor="formrow-nameKa-input">{props.t("_COURIER_NAME_AND_SURNAME_")}</Label>
                                    <Input
                                        value={name}
                                        disabled={disabledInput}
                                        style={nameErr ? {borderColor: 'red'} : null}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text" className="form-control" id="formrow-nameKa-input"/>
                                </Col>


                                <Col md={12}>
                                    <Label className="form-label"
                                           htmlFor="formrow-nameKa-input">{props.t("_INVOICE_PHONE_")}</Label>
                                    <Input
                                        value={phone}
                                        disabled={disabledInput}
                                        style={phoneErr ? {borderColor: 'red'} : null}
                                        onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ''))}

                                        type="text" className="form-control" id="formrow-nameKa-input"/>
                                </Col>


                            </Row>


                            <hr className="my-0"/>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button
                                    onClick={handleCreateCourierService}
                                    color="primary"
                                    className="btn btn-primary btn-lg btn-block waves-effect waves-light mb-1"
                                >
                                    {props.t("_COURIER_ORDER_")}
                                </Button>

                                <p>
                                    <strong> {props.t("_TARIFF_")}: </strong><br/>
                                    {
                                        city === '_TBILISI_'
                                            ? <>
                                                <i>
                                                    {props.t("_COURIER_5KG_")} <br/>
                                                    {props.t("_COURIER_10KG_")} <br/>
                                                    {props.t("_COURIER_15KG_")} <br/>
                                                    {props.t("_COURIER_20KG_")} <br/>
                                                    {props.t("_COURIER_REGION_")} <br/>
                                                    {props.t("_COURIER_DELIVERY_TIME_")} <br/>
                                                </i>
                                            </>
                                            : null
                                    }
                                    {
                                        city !== '_TBILISI_'
                                            ? <>
                                                <i>
                                                    {props.t("_COURIER_REGION_5KG_")} <br/>
                                                    {props.t("_COURIER_REGION_10KG_")} <br/>
                                                    {props.t("_COURIER_REGION_15KG_")} <br/>
                                                    {props.t("_COURIER_REGION_20KG_")} <br/>

                                                    {props.t("_COURIER_DABA_ORDER_")} <br/>
                                                    {props.t("_COURIER_DELIVERY_TIME_")} <br/>
                                                </i>
                                            </>
                                            : null
                                    }

                                    <i>
                                        {props.t("_COURIER_PAYMENT_IS_MADE_BY_COURIER_")}
                                    </i>

                                </p>

                                <div style={{paddingTop: '150px'}}>

                                </div>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay"/>
        </React.Fragment>
    );
};


const mapStateToProps = state => {
    const {
        Auth,
        Layout,
        Courier
    } = state
    return {
        Auth,
        Layout,
        Courier
    }
};

export default connect(mapStateToProps)(withTranslation()(RightbarCourier));
