import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import ReactGA from "react-ga4";
import store from "./store"
import AnalyticsTracker from "./components/AnalyticsTracker";

ReactGA.initialize("G-2LHZVZS6JK");

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const app = (
  <Provider store={store}>
    <BrowserRouter>
        <AnalyticsTracker />
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
