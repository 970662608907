import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Label, Row, Card} from "reactstrap";

import {connect, useDispatch} from "react-redux";
import {isEmpty} from "lodash";
//SimpleBar
import SimpleBar from "simplebar-react";

import {Link} from "react-router-dom";

import "./rightbar.scss";
//Import images

import {withTranslation} from "react-i18next";
import {showRightSidebarUpdateParcelAction} from "../../store/layout/actions";
import Dropzone from "react-dropzone";
import {
    productNull, productOneNull, updateProductDeclaration, updateProductDeclarationWithFile
} from "../../store/products/actions";

import {shopsTypes} from '../../helpers/types'
import {rightbarParcelError} from "../../helpers/notification";


const RightbarUpdateParcel = props => {
    const dispatch = useDispatch();
    const [error, setError] = useState(false)
    const [productId, setProductId] = useState('')
    const [personalParcel, setPersonalParcel] = useState(false)
    const [clearance, setClearance] = useState(false)
    const [custom, setCustom] = useState(false)


    const [productOrderId, setProductOrderId] = useState('')
    const [productOrderIdErr, setProductOrderIdErr] = useState(false)
    const [productName, setProductName] = useState('')
    const [productNameErr, setProductNameErr] = useState(false)
    const [quantity, setQuantity] = useState('')
    const [quantityErr, setQuantityErr] = useState(false)
    const [quantityErrMessage, setQuantityErrMessage] = useState('რაოდენობა არ შეიძლება იყოს 0 ის ტოლი')
    const [senderCountryId, setSenderCountryId] = useState(1)
    const [senderCountryIdErr, setSenderCountryIdErr] = useState(false)
    const [shop, setShop] = useState('')
    const [shopOther, setShopOther] = useState('')
    const [shopErr, setShopErr] = useState(false)
    const [shopOtherErr, setShopOtherErr] = useState(false)
    const [amount, setAmount] = useState('')
    const [file, setFile] = useState('')
    const [amountErr, setAmountErr] = useState(false)
    const [currency, setCurrency] = useState('')
    const [currencyErr, setCurrencyErr] = useState(false)
    const [description, setDescription] = useState('')
    const [alertMessage, setAlertMessage] = useState(false)


    const [senderName, setSenderName] = useState('')
    const [senderNameErr, setSenderNameErr] = useState(false)

    const [branches, setBranches] = useState(false)


    useEffect(() => {
        if (props.Products.product !== null) {
            if (!isEmpty(props.Products.product.shop)) {
                if (props.Products.product.shop === '_OTHER_SHOP_') {
                    setShop('other')
                } else {
                    setShop(props.Products.product.shop)
                }

            }

            setShop(props.Products.product.shop)
            setShopOther(props.Products.product.otherShop)

            setPersonalParcel(props.Products.product.personalParcel)
            setClearance(props.Products.product.clearance)
            setProductId(props.Products.product._id)
            setProductOrderId(props.Products.product.productOrderId)
            setSenderName(props.Products.product.senderName)
            setProductName(props.Products.product.productName)
            setQuantity(props.Products.product.quantity ? props.Products.product.quantity : '')
            setFile(props.Products.product.file)
            // if (props.Products.product.file) {
            //     setselectedFiles(oldArray => [...oldArray, props.Products.product.file]);
            // }
            setSenderCountryId(props.Products.product.senderCountryId)

            setAmount(props.Products.product.price ? props.Products.product.price : '')
            setCurrency(props.Products.product.currency ? props.Products.product.currency : '')
            setDescription(props.Products.product.description)
            setBranches(props.Products.product.branch)
        }
    }, [props.Products.product])

    useEffect(() => {
        if (personalParcel) {
            setShop('-')
        } else {
            setShop('')
        }

    }, [])

    useEffect(() => {
        if (amount && amount.toString().charAt(0) !== '0') {
            let am = 0
            if (currency) {
                setCurrencyErr(false)
                if (currency === 'USD') {
                    am = (amount * props.Rates.USD).toFixed(2)
                } else if (currency === 'EUR') {
                    am = (amount * props.Rates.EUR).toFixed(2)
                } else if (currency === 'TRY') {
                    am = (amount * props.Rates.TRY).toFixed(2)
                } else if (currency === 'CNY') {
                    am = (amount * props.Rates.CNY / 10).toFixed(2)
                } else if (currency === 'GEL') {
                    am = amount
                }

            } else {
                setCurrencyErr(true)
            }
            if (parseFloat(am) >= 300) {
                setAlertMessage(true)
            } else {
                setAlertMessage(false)
            }
        }
    }, [amount, currency])

    const handleAddParcel = () => {

        if (!quantity) {
            setQuantityErr(true)
        } else {
            setQuantityErr(false)
        }
        if (!amount) {
            setAmountErr(true)
        } else {
            setAmountErr(false)
        }

        if (!productOrderId) {
            setProductOrderIdErr(true)
        } else {
            setProductOrderIdErr(false)
        }
        if (!productName) {
            setProductNameErr(true)
        } else {
            setProductNameErr(false)
        }
        if (!senderCountryId) {
            setSenderCountryIdErr(true)
        } else {
            setSenderCountryIdErr(false)
        }

        if (!productName) {
            setProductNameErr(true)
        } else {
            setProductNameErr(false)
        }
        if (!personalParcel) {
            if (!shop) {
                setShopErr(true)
            } else {
                setShopErr(false)
            }
        }

        if (quantity) {
            if (quantity.toString().charAt(0) === '0') {
                setQuantityErr(true)
                // return false
            } else {
                if (parseInt(quantity) === 0 || !quantity) {
                    setQuantityErr(true)
                } else {
                    setQuantityErr(false)
                }
            }
        } else {
            setQuantityErr(true)
        }

        if (amount) {
            if (amount.toString().charAt(0) === '0' || amount.toString().charAt(0) === '.') {
                setAmountErr(true)
                if (amount.toString().charAt(1) === '.') {
                    setAmountErr(false)
                }
            } else {
                setAmountErr(false)
            }
        } else {
            setAmountErr(true)
        }


        let am = 0
        if (currency === 'USD') {
            am = (amount * props.Rates.USD).toFixed(2)
        } else if (currency === 'EUR') {
            am = (amount * props.Rates.EUR).toFixed(2)
        } else if (currency === 'TRY') {
            am = (amount * props.Rates.TRY).toFixed(2)
        } else if (currency === 'CNY') {
            am = (amount * props.Rates.CNY / 10).toFixed(2)
        } else if (currency === 'GEL') {
            am = amount
        }
        if (parseFloat(am) >= 300) {
            if (selectedFiles.length === 0) {
                setSelectedFilesErr2(true)
            } else {
                setSelectedFilesErr2(false)
            }
        } else {
            if (clearance) {


                if (isEmpty(file) && isEmpty(selectedFiles)) {
                    setSelectedFilesErr2(true)
                } else {
                    setSelectedFilesErr2(false)
                }
            } else {
                setSelectedFilesErr2(false)
            }
        }

        if (productOrderId && productName && quantity && parseInt(quantity) !== 0 && parseFloat(amount) > 0 && senderCountryId && (shop || personalParcel) && currency) {
            if (quantity.toString().charAt(0) !== '0' && parseInt(quantity) !== 0) {
                if (shop === '_OTHER_SHOP_') {
                    if (!shopOther) {
                        rightbarParcelError('error');
                        setShopOtherErr(true)
                        return false
                    } else {
                        setShopOtherErr(false)
                    }
                }

                let item = {
                    productId,
                    personalParcel,
                    clearance,
                    am,
                    productOrderId,
                    quantity,
                    productName,
                    senderCountryId,
                    shop,
                    shopOther,
                    amount,
                    currency,
                    description,
                    senderName,
                    branches
                }
                /*
                                if (shop === 'other'  ) {
                                    if (!shopOther){
                                        setShopOtherErr(true)
                                        rightbarParcelError('error');
                                        return false
                                    }else {
                                        setShopOtherErr(false)
                                    }
                                }*/

                if (parseFloat(am) >= 300 || clearance === true) {
                    if (file || selectedFiles.length > 0) {
                        // setcustom
                        setCustom(true)
                    } else {
                        return false
                    }
                }

                if (selectedFiles.length > 0) {
                    let formData = new FormData();
                    formData.append('file', selectedFiles[0]);
                    formData.append('inputs', JSON.stringify(item));
                    props.dispatch(updateProductDeclarationWithFile(formData))
                } else {
                    props.dispatch(updateProductDeclaration(item))
                }


                /*  if (personalParcel) {
                     if (selectedFiles.length > 0) {
                         let formData = new FormData();
                         formData.append('file', selectedFiles[0]);
                         formData.append('inputs', JSON.stringify(item));
                         props.dispatch(updateProductDeclarationWithFile(formData))
                     } else {
                         props.dispatch(updateProductDeclaration(item))
                     }
                 } else {
                     if (selectedFiles.length > 0) {
                         let formData = new FormData();
                         formData.append('file', selectedFiles[0]);
                         formData.append('inputs', JSON.stringify(item));
                         props.dispatch(updateProductDeclarationWithFile(formData))
                     } else {
                         props.dispatch(updateProductDeclaration(item))
                     }
                 }*/
            }
        } else {
            rightbarParcelError('error');
        }
    }


    const handleCloseForm = () => {
        dispatch(productOneNull())
        dispatch(showRightSidebarUpdateParcelAction(false))
    }

    const handleRemoveFiles = () => {
        setFile('')
        setselectedFiles([])
    }
    const [selectedFiles, setselectedFiles] = useState([])
    const [selectedFilesErr2, setSelectedFilesErr2] = useState(false)
    const [selectedFilesErr, setSelectedFilesErr] = useState(false)
    const [selectedFilesErr3, setSelectedFilesErr3] = useState(false)

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    function handleAcceptedFiles(files) {

        files.map(file => {
                if (file.size >= 3022117) {
                    setSelectedFilesErr3(true)
                    setselectedFiles([])
                    return false
                } else {
                    setSelectedFilesErr3(false)
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                    })
                }
            }
        )
        if (files.length > 0) {
            setselectedFiles(files)
            setSelectedFilesErr(false)
        } else {
            setselectedFiles([])
            setSelectedFilesErr(true)
        }
    }

    return (
        <React.Fragment>
            <div className="right-bar">
                <SimpleBar style={{height: "100%"}}>
                    <div className="h-100">
                        <div className="rightbar-title d-flex align-items-center px-3 py-4">
                            <h5 className="m-0 me-2" style={{paddingLeft: '10px'}}>{props.t("_UPDATE_PARCEL_")}</h5>
                            <span
                                style={{cursor: 'pointer'}}
                                onClick={handleCloseForm}
                                className="right-bar-toggle ms-auto"
                            >
                                <i className="mdi mdi-close noti-icon"/>
                            </span>
                        </div>

                        <hr className="my-0"/>

                        <div className="p-4">
                            {
                                !isEmpty(productOrderId)
                                    ?
                                    <Row>

                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label className="form-label"
                                                       htmlFor="formrow-email-input">{props.t("_WAYBILL_NUMBER_")} </Label>
                                                <Input type="text"
                                                       value={productOrderId}
                                                       disabled
                                                       style={productOrderIdErr ? {borderColor: 'red'} : null}
                                                       onChange={(e) => setProductOrderId(e.target.value.replace(/[^A-Za-z0-9]+/g, ''))}
                                                       className="form-control" id="formrow-email-input"/>
                                            </div>
                                        </Col>


                                        <Col md={12}>
                                            <div className="mb-3">
                                                <div className="d-flex">
                                                    <div className="square-switch">
                                                        <input
                                                            type="checkbox"
                                                            id="square-switch2"
                                                            switch="none"
                                                            checked={personalParcel}
                                                            onChange={() => {
                                                                setPersonalParcel(!personalParcel)
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor="square-switch2"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />

                                                    </div>
                                                    <Label className="form-label"
                                                           htmlFor="square-switch2"> {props.t("_PERSONAL_PARCELS_")}  </Label>
                                                </div>
                                            </div>
                                        </Col>

                                        {
                                            personalParcel
                                                ? <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label"
                                                               htmlFor="formrow-email-input">გამომგზავნი/მაღაზიის
                                                            სახელი </Label>
                                                        <Input type="text"
                                                               value={senderName}
                                                               style={senderNameErr ? {borderColor: 'red'} : null}
                                                               onChange={(e) => setSenderName(e.target.value)}
                                                               className="form-control" id="formrow-email-input"/>
                                                    </div>
                                                </Col>

                                                : null
                                        }
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="formrow-rePassword-input">{props.t('_Choose_a_branch')} </Label>
                                                <select
                                                    value={branches}
                                                    onChange={(e) => setBranches(e.target.value)}
                                                    className="form-control form-control-border">
                                                    <option value="didube">{props.t('_Branch_Address_1')}</option>
                                                    <option value="gldani">{props.t('_Branch_Address_2')}</option>
                                                    <option value="isani">{props.t('_Branch_Address_3')}</option>
                                                    <option value="kutaisi_1">{props.t('kutaisi_1')}</option>
                                                </select>
                                            </div>
                                        </Col>

                                        <Col md={12}>
                                            <div className="mb-3">
                                                <div className="col-lg-12 col-sm-12">
                                                    <div className="form-group  input-sl">
                                                        <div className="col-lg-12 col-sm-12">

                                                            <div className="form-group">
                                                                <label
                                                                    className='bolder-label'> {props.t("_PRODUCT_NAME_")}</label>
                                                                <select
                                                                    value={productName}
                                                                    style={productNameErr ? {borderColor: 'red'} : null}
                                                                    onChange={(e) => setProductName(e.target.value)}
                                                                    className="form-control form-control-border">
                                                                    <option value="">--</option>
                                                                    <option
                                                                        value="_VARIOUS_ELECTRONIC_DEVICES_">{props.t("_VARIOUS_ELECTRONIC_DEVICES_")}</option>
                                                                    <option
                                                                        value="_BAGS_AND_HOLDERS">{props.t("_BAGS_AND_HOLDERS")}</option>
                                                                    <option
                                                                        value="_SHOES_">{props.t("_SHOES_")}</option>
                                                                    <option
                                                                        value="_TELEPHONE_AND_NETWORK_DEVICES">{props.t("_TELEPHONE_AND_NETWORK_DEVICES")}</option>
                                                                    <option
                                                                        value="_CLOTHING_ALL_TYPES_OF_CLOTHING_">{props.t("_CLOTHING_ALL_TYPES_OF_CLOTHING_")}</option>
                                                                    <option
                                                                        value="_FOOD_SUPPLEMENTS_">{props.t("_FOOD_SUPPLEMENTS_")}</option>
                                                                    <option
                                                                        value="_TOYS_AND_SPORTS_EQUIPMENT_">{props.t("_TOYS_AND_SPORTS_EQUIPMENT_")}</option>
                                                                    <option
                                                                        value="_WATCHES_">{props.t("_WATCHES_")}</option>
                                                                    <option
                                                                        value="_PERFUMERY_AND_COSMETICS_">{props.t("_PERFUMERY_AND_COSMETICS_")}</option>
                                                                    <option
                                                                        value="_PRINTED_PRODUCTS_BOOKS_BROCHURES_">{props.t("_PRINTED_PRODUCTS_BOOKS_BROCHURES_")}</option>
                                                                    <option
                                                                        value="_MUSICAL_INSTRUMENTS_AND_THEIR_PARTS_">{props.t("_MUSICAL_INSTRUMENTS_AND_THEIR_PARTS_")}</option>
                                                                    <option
                                                                        value="_GLASSWARE_">{props.t("_GLASSWARE_")}</option>
                                                                    <option
                                                                        value="_MEDICATIONS_">{props.t("_MEDICATIONS_")}</option>
                                                                    <option
                                                                        value="_COMPUTER_LAPTOP_AND_THEIR_PARTS_">{props.t("_COMPUTER_LAPTOP_AND_THEIR_PARTS_")}</option>
                                                                    <option
                                                                        value="_TOOLS_AND_HAND_TOOLS_">{props.t("_TOOLS_AND_HAND_TOOLS_")}</option>
                                                                    <option
                                                                        value="_LIGHTING_CHANDELIERS_LAMPS_HEADLIGHTS_">{props.t("_LIGHTING_CHANDELIERS_LAMPS_HEADLIGHTS_")}</option>
                                                                    <option
                                                                        value="_BIJOUTERIE_">{props.t("_BIJOUTERIE_")}</option>
                                                                    <option
                                                                        value="_AUTO_PARTS_">{props.t("_AUTO_PARTS_")}</option>
                                                                    <option
                                                                        value="_VARIOUS_FINISHED_PRODUCTS_">{props.t("_VARIOUS_FINISHED_PRODUCTS_")}</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>


                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label className="form-label"
                                                       htmlFor="formrow-rePassword-input"> {props.t("_COUNTRY_OF_SENDING_")}</Label>

                                                <select
                                                    disabled={true}
                                                    value={senderCountryId}
                                                    onChange={(e) => setSenderCountryId(parseInt(e.target.value))}
                                                    style={senderCountryIdErr ? {borderColor: 'red'} : null}
                                                    className="form-control form-control-border">
                                                    <option value="">--</option>
                                                    <option value="1">{props.t("_CHINA_")}</option>
                                                    <option value="2">{props.t("_USA_")}</option>
                                                    <option value="3">{props.t("_TURKEY_")}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        {
                                            personalParcel
                                                ? null
                                                :
                                                <>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label"
                                                                   htmlFor="formrow-rePassword-input">{props.t("_ONLINE_MARKET_")}   </Label>

                                                            {
                                                                senderCountryId
                                                                    ?
                                                                    <select
                                                                        value={shop}
                                                                        style={shopErr ? {borderColor: 'red'} : null}
                                                                        onChange={(e) => setShop(e.target.value)}
                                                                        className="form-control form-control-border">
                                                                        <option value="">--</option>
                                                                        {
                                                                            senderCountryId === 1
                                                                                ? <>
                                                                                    <option value="TAOBAO.COM">TAOBAO.COM</option>
                                                                                    <option value="ALIEXPRESS.COM">ALIEXPRESS.COM</option>
                                                                                    <option value="ALIBABA.COM">ALIBABA.COM</option>
                                                                                    <option value="TMALL.COM">TMALL.COM</option>
                                                                                    <option value="PINDOUDOU">PINDOUDOU </option>
                                                                                </>
                                                                                : null
                                                                        }
                                                                        {
                                                                            senderCountryId === 2
                                                                                ? <>
                                                                                    <option value="EBAY.COM">EBAY.COM  </option>
                                                                                    <option value="AMAINHOBBIES.COM">AMAINHOBBIES.COM  </option>
                                                                                    <option value="AMAZON.COM">AMAZON.COM
                                                                                    </option>
                                                                                    <option value="6PM.COM">6PM.COM</option>
                                                                                    <option value="BOXYCHARM.IPSY.COM">BOXYCHARM.IPSY.COM</option>
                                                                                    <option value="SHEIN.COM">SHEIN.COM</option>
                                                                                </>
                                                                                : null
                                                                        }
                                                                        {
                                                                            senderCountryId === 3
                                                                                ? <>
                                                                                    <option
                                                                                        value="trendyol.com">trendyol.com
                                                                                    </option>
                                                                                    <option
                                                                                        value="hepsiburada.com">hepsiburada.com
                                                                                    </option>
                                                                                    <option
                                                                                        value="fashfed.com">fashfed.com
                                                                                    </option>
                                                                                    <option
                                                                                        value="amazon.com.tr">amazon.com.tr
                                                                                    </option>
                                                                                </>
                                                                                : null
                                                                        }
                                                                        <option value="_OTHER_SHOP_">სხვა მაღაზია
                                                                        </option>

                                                                    </select>
                                                                    : null
                                                            }

                                                        </div>
                                                    </Col>
                                                    {
                                                        shop === '_OTHER_SHOP_'
                                                            ?
                                                            <Col md={12}>
                                                                <Label className="form-label"
                                                                       htmlFor="formrow-price-input">
                                                                    {props.t("_OTHER_SHOP_")}
                                                                </Label>
                                                                <Input
                                                                    value={shopOther}
                                                                    style={shopOtherErr ? {borderColor: 'red'} : null}
                                                                    onChange={(e) => setShopOther(e.target.value.replace(/[^A-Za-z0-9\.]+/g, ''))}
                                                                    type="text" className="form-control"
                                                                    id="formrow-price-input"/>
                                                            </Col>

                                                            : null
                                                    }

                                                </>
                                        }


                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Row>
                                                    <Col md={8}>
                                                        <Label className="form-label"
                                                        >{props.t("_QUANTITY_")}</Label>
                                                        <Input
                                                            value={quantity}
                                                            style={quantityErr ? {borderColor: 'red'} : null}
                                                            onChange={(e) => setQuantity(e.target.value.replace(/\D/, ''))}
                                                            type="text" className="form-control"/>

                                                        {
                                                            quantityErr && quantity === '0'
                                                                ? <div style={{color: 'red'}}>
                                                                    <i>
                                                                        {quantityErrMessage}
                                                                    </i>
                                                                </div>
                                                                : null
                                                        }


                                                    </Col>

                                                    <Col md={8}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-price-input">{props.t("_AMOUNT_")}</Label>
                                                        <Input
                                                            value={amount}
                                                            style={amountErr ? {borderColor: 'red'} : null}
                                                            onChange={(e) => setAmount(e.target.value.replace(/[^0-9\.]/g, ''))}
                                                            type="text" className="form-control"
                                                            id="formrow-price-input"/>

                                                        {
                                                            alertMessage
                                                                ?
                                                                <div style={{color: 'red'}}>
                                                                    <i>

                                                                        {props.t("_PRODUCT_300_LARI")}
                                                                    </i>
                                                                </div>
                                                                : null
                                                        }

                                                    </Col>
                                                    <Col md={4}>
                                                        <Label className="form-label"
                                                               htmlFor="formrow-nameKa-input">{props.t("_CURRENCY_")}</Label>

                                                        <select
                                                            value={currency}
                                                            onChange={(e) => setCurrency(e.target.value)}
                                                            style={currencyErr ? {borderColor: 'red'} : null}
                                                            className="form-control form-control-border">
                                                            <option value="">---</option>
                                                            <option value="USD">USD</option>
                                                            <option value="EUR">EUR</option>
                                                            <option value="CNY">CNY</option>
                                                            <option value="TRY">TRY</option>
                                                            <option value="GEL">GEL</option>
                                                        </select>

                                                    </Col>
                                                </Row>

                                            </div>
                                        </Col>


                                        <Col md={12}>
                                            <div className="mb-3">
                                                <div className="d-flex">
                                                    <div className="square-switch">

                                                        <input
                                                            type="checkbox"
                                                            id="square-switch1"
                                                            switch="none"
                                                            checked={clearance}
                                                            onChange={() => {
                                                                setClearance(!clearance)
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor="square-switch1"
                                                            data-on-label="On"
                                                            data-off-label="Off"
                                                        />

                                                    </div>
                                                    <Label className="form-label"
                                                           htmlFor="formrow-password-input"> {props.t("_I_WANT_TO_CUSTOMS_CLEARANCE_")} </Label>

                                                </div>
                                            </div>
                                        </Col>


                                        <Col md={12}>
                                            <div className="mb-3">
                                                {
                                                    file || selectedFiles.length > 0
                                                        ?
                                                        <div className="dropzone-previews mt-3" id="file-previews">
                                                            {selectedFiles.map((f, i) => {
                                                                return (
                                                                    <Card
                                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={i + "-file"}
                                                                    >
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">

                                                                                <Col>
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="text-muted font-weight-bold"
                                                                                    >
                                                                                        {f.name}
                                                                                    </Link>
                                                                                    <p className="mb-0">
                                                                                        <strong>{f.formattedSize}</strong>
                                                                                    </p>
                                                                                    <span
                                                                                        onClick={handleRemoveFiles}
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            right: '15px',
                                                                                            top: '0px',
                                                                                            cursor: 'pointer'
                                                                                        }}>
                                                                                x
                                                                            </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Card>
                                                                )
                                                            })}
                                                        </div>
                                                        :
                                                        <>
                                                            <Dropzone
                                                                accept="image/*,application/pdf"
                                                                // accept="image/jpeg, image/png"
                                                                onDrop={acceptedFiles => {
                                                                    handleAcceptedFiles(acceptedFiles)
                                                                }}
                                                            >
                                                                {({getRootProps, getInputProps}) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            style={{
                                                                                fontSize: '17px'
                                                                            }}
                                                                            className="dz-message needsclick"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} />
                                                                            <p> {props.t("_UPLOAD_INVOICE_")} </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>

                                                        </>
                                                }
                                                {
                                                    selectedFilesErr
                                                        ?
                                                        <span
                                                            style={{color: 'red'}}><i>{props.t("_OTHER_ONLY_JPG_")}</i></span>
                                                        : null
                                                }
                                                {
                                                    selectedFilesErr2
                                                        ? <span
                                                            style={{color: 'red'}}><i> {props.t("_OTHER_UPLOAD_FILE_")}</i></span>
                                                        : null
                                                }
                                                {
                                                    selectedFilesErr3
                                                        ? <span
                                                            style={{color: 'red'}}><i> {props.t("_OTHER_UPLOAD_FILE_SIZE_")}</i></span>
                                                        : null
                                                }
                                                {
                                                    file && selectedFiles.length === 0
                                                        ? <>
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        {/*  <Col className="col-auto">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        height="80"
                                                                        className="avatar-sm rounded bg-light"

                                                                        src={''}
                                                                    />
                                                                </Col>*/}
                                                                        <Col>
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {file}
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                <strong>{}</strong>
                                                                            </p>
                                                                            <span
                                                                                onClick={handleRemoveFiles}
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    right: '15px',
                                                                                    top: '0px',
                                                                                    cursor: 'pointer'
                                                                                }}>
                                                                                x
                                                                    </span>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Card>

                                                        </>
                                                        : null

                                                }

                                            </div>
                                        </Col>

                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label className="form-label"
                                                       htmlFor="formrow-nameEn-input">  {props.t("_NOTE_")}</Label>

                                                <Input
                                                    type="textarea"
                                                    id="textarea"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    maxLength="160"
                                                    rows="3"
                                                    placeholder={props.t("_THE_FIELD_IS_FOR_USER_")}
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                    : null
                            }


                            <div className="d-grid gap-2">
                                <Button
                                    onClick={handleAddParcel}
                                    color="primary"
                                    className="btn btn-primary btn-lg btn-block waves-effect waves-light mb-1"
                                >
                                    {props.t("_UPDATE_PARCEL_")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay"/>
        </React.Fragment>
    );
};


const mapStateToProps = state => {
    const {
        Layout,
        Products,
        Rates
    } = state
    return {
        Layout,
        Products,
        Rates
    }
};

export default connect(mapStateToProps)(withTranslation()(RightbarUpdateParcel));
