import React, {useEffect, useState} from 'react';
import {Card, Col, Input, Label, Modal, Row} from "reactstrap";
import {connect, useDispatch} from "react-redux";

//SimpleBar
import SimpleBar from "simplebar-react";

import {Link, withRouter} from "react-router-dom";

import "./rightbar.scss";
//Import images

import {withTranslation} from "react-i18next";
import {showRightSidebarAction} from "../../store/layout/actions";
import {notification} from "antd";
import {signUp, signUpWithFile} from "../../store/auth/actions";
import Dropzone from "react-dropzone";
import {getPage} from "../../store/page/actions";
import {validateEmail} from "../../helpers/regexs";

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'რეგისტრაციისთავის აუცილებელია შეავსოთ ყველა მოცემული ველი',
    });
};
const modalError2 = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'ინდ მეწარმის რეგისტრაცისთვის აუცილებელია ატვირთოთ ამონაწერი',
    });
};


function ucFirst(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}


const RightSidebar = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        let item = {
            slug: 'terms'
        }
        dispatch(getPage(item))
    }, [])

    let ucFirstDescription = 'description' + ucFirst(props.i18n.language)
    const [modal_standard, setmodal_standard] = useState(false)

    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    const [eye, setEye] = useState(true)
    const [eye2, setEye2] = useState(true)

    const [foreignCitizen, setForeignCitizen] = useState(false)
    const [type, setType] = useState(1)
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [nameKa, setNameKa] = useState('')
    const [lastNameKa, setLastNameKa] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [lastNameEn, setLastNameEn] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [filial, setFilial] = useState('didube');
    const [personId, setPersonId] = useState('')
    const [orgName, setOrgName] = useState('')
    const [orgNameEn, setOrgNameEn] = useState('')
    const [orgId, setOrgId] = useState('')
    const [checked, setChecked] = useState(false)
    const [checkedIndMecarme, setCheckedIndMecarme] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([])
    const [selectedFilesErr, setselectedFilesErr] = useState(false)

    const [checkedERR, setCheckedERR] = useState(false)
    const [phoneERR, setPhoneERR] = useState(false)
    const [emailERR, setEmailERR] = useState(false)
    const [passwordERR, setPasswordERR] = useState(false)
    const [rePasswordERR, setRePasswordERR] = useState(false)
    const [nameKaERR, setNameKaERR] = useState(false)
    const [lastNameKaERR, setLastNameKaERR] = useState(false)
    const [nameEnERR, setNameEnERR] = useState(false)
    const [lastNameEnERR, setLastNameEnERR] = useState(false)
    const [cityERR, setCityERR] = useState(false)
    const [addressERR, setAddressERR] = useState(false)
    const [personIdERR, setPersonIdERR] = useState(false)
    const [orgNameERR, setOrgNameERR] = useState(false)
    const [orgNameEnERR, setOrgNameEnERR] = useState(false)
    const [orgIdERR, setOrgIdERR] = useState(false)

    const eng = new Array(97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 87, 82, 84, 83, 67, 74, 90);
    const geo = new Array(4304, 4305, 4330, 4307, 4308, 4324, 4306, 4336, 4312, 4335, 4313, 4314, 4315, 4316, 4317, 4318, 4325, 4320, 4321, 4322, 4323, 4309, 4332, 4334, 4327, 4310, 4333, 4326, 4311, 4328, 4329, 4319, 4331, 91, 93, 59, 39, 44, 46, 96);


    const handleGeo = (e) => {
        let c
        let code = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
        let found = false;
        for (let i = 0; i <= geo.length; i++) {
            if (eng[i] === code) {
                c = geo[i];
                found = true;
            }
        }
        if (found) {
            if (e.target.name === 'nameKa') {
                setNameKa(
                    prev => prev + String.fromCharCode(c),
                );
            } else if (e.target.name === 'orgName') {
                setOrgName(
                    prev => prev + String.fromCharCode(c),
                );
            } else {
                setLastNameKa(
                    prev => prev + String.fromCharCode(c),
                );
            }

        }
    }

    const [selectedFilesErr3, setSelectedFilesErr3] = useState(false)

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    function handleAcceptedFiles(files) {
        files.map(file => {
                if (file.size >= 3022117) {
                    setSelectedFilesErr3(true)
                    setselectedFiles([])
                    return false
                } else {
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                    })
                }
            }
        )
        setselectedFiles(files)
    }

    const handleEye = () => {
        setEye(!eye)
    }
    const handleEye2 = () => {
        setEye2(!eye2)
    }
    const handleRegistration = () => {
        if (!email) {
            setEmailERR(true)
        } else {
            if (validateEmail(email)) {
                setEmailERR(false)
            } else {
                setEmailERR(true)
            }
        }
        if (!password) {
            setPasswordERR(true)
        } else {
            setPasswordERR(false)
        }
        if (!rePassword) {
            setRePasswordERR(true)
        } else {
            setRePasswordERR(false)
        }
        if (password && rePassword) {
            if (password === rePassword) {
                setRePasswordERR(false)
                setPasswordERR(false)
            } else {
                setRePasswordERR(true)
                setPasswordERR(true)
            }
        }

        if (!foreignCitizen) {
            if (!nameKa) {
                setNameKaERR(true)
            } else {
                setNameKaERR(false)
            }
            if (!lastNameKa) {
                setLastNameKaERR(true)
            } else {
                setLastNameKaERR(false)
            }
        }

        if (!nameEn) {
            setNameEnERR(true)
        } else {
            setNameEnERR(false)
        }
        if (!lastNameEn) {
            setLastNameEnERR(true)
        } else {
            setLastNameEnERR(false)
        }
        if (!city) {
            setCityERR(true)
        } else {
            setCityERR(false)
        }
        if (!address) {
            setAddressERR(true)
        } else {
            setAddressERR(false)
        }
        if (!phone) {
            setPhoneERR(true)
        } else {
            if (phone.length === 9) {
                setPhoneERR(false)
            } else {
                setPhoneERR(true)
            }
        }
        if (!checked) {
            setCheckedERR(true)
        } else {
            setCheckedERR(false)
        }

        if (type === 1) {
            if (!personId) {
                setPersonIdERR(true)
            } else {
                if (personId.length === 11) {
                    setPersonIdERR(false)
                } else {
                    setPersonIdERR(true)
                }
            }
        } else if (type === 2) {
            if (!orgName) {
                setOrgNameERR(true)
            } else {
                setOrgNameERR(false)
            }
            if (!orgId) {
                setOrgIdERR(true)
            } else {
                if (orgId.length === 9) {
                    setOrgIdERR(false)
                } else {
                    setOrgIdERR(true)
                }
            }
        }

        let item = {
            type,
            email,
            password,
            nameKa,
            lastNameKa,
            nameEn,
            lastNameEn,
            phone,
            city,
            address,
            personId,
            orgName,
            orgNameEn,
            orgId,
            filial,
            indEntrepreneur: checkedIndMecarme.toString(),
            foreignCitizen
        }

        if (type === 1) {
            if (foreignCitizen) {
                if (email && validateEmail(email) && password && rePassword && password === rePassword && nameEn && lastNameEn
                    && city && address && personId && phone && checked && phone.length === 9) {
                    dispatch(signUp(item))
                } else {
                    modalError('error');
                }
            } else {
                if (email && validateEmail(email) && password && rePassword && password === rePassword && nameKa && lastNameKa && nameEn && lastNameEn
                    && city && address && personId && phone && checked && phone.length === 9 && personId.length === 11) {
                    dispatch(signUp(item))
                } else {
                    modalError('error');
                }
            }

        } else if (type === 2) {
            if (checkedIndMecarme) {
                if (foreignCitizen) {
                    if (email && validateEmail(email) && password && rePassword && password === rePassword && nameEn && lastNameEn
                        && city && address && phone && checked && phone.length === 9) {
                        if (selectedFiles.length > 0) {
                            setselectedFilesErr(false)
                            let formData = new FormData();
                            formData.append('file', selectedFiles[0]);
                            formData.append('inputs', JSON.stringify(item));

                            dispatch(signUpWithFile(formData))
                        } else {
                            setselectedFilesErr(true)

                            modalError2('error');
                        }
                    } else {
                        modalError('error');
                    }
                } else {
                    if (email && validateEmail(email) && password && rePassword && password === rePassword && nameKa && lastNameKa && nameEn && lastNameEn
                        && city && address && phone && checked && phone.length === 9 && personId.length === 11) {
                        if (selectedFiles.length > 0) {
                            setselectedFilesErr(false)
                            let formData = new FormData();
                            formData.append('file', selectedFiles[0]);
                            formData.append('inputs', JSON.stringify(item));

                            dispatch(signUpWithFile(formData))
                        } else {
                            setselectedFilesErr(true)

                            modalError2('error');
                        }
                    } else {
                        modalError('error');
                    }
                }


            } else {
                if (foreignCitizen) {
                    if (email && validateEmail(email) && password && rePassword && password === rePassword && nameEn && lastNameEn
                        && city && address && phone && checked && orgName && orgNameEn && orgId && phone.length === 9 && orgId.length === 9) {


                        let item = {
                            type,
                            email,
                            password,
                            nameKa,
                            lastNameKa,
                            nameEn,
                            lastNameEn,
                            phone,
                            city,
                            address,
                            personId,
                            orgName,
                            orgNameEn,
                            orgId,
                            filial,
                            indEntrepreneur: checkedIndMecarme.toString(),
                            foreignCitizen
                        }


                        dispatch(signUp(item))
                    } else {
                        modalError('error');
                    }
                } else {
                    if (email && validateEmail(email) && password && rePassword && password === rePassword && nameKa && lastNameKa && nameEn && lastNameEn
                        && city && address && phone && checked && orgName && orgNameEn && orgId && phone.length === 9 && orgId.length === 9) {
                        dispatch(signUp(item))
                    } else {
                        modalError('error');
                    }
                }


            }


        }


    }

    const handleCloseForm = () => {
        dispatch(showRightSidebarAction(false))
    }

    const handleLink = () => {
        props.history.push('/terms')
        dispatch(showRightSidebarAction(false))
    }
    return (
        <React.Fragment>
            <div className="right-bar">
                <SimpleBar style={{height: "100%"}}>
                    <div data-simplebar className="h-100">
                        <div className="rightbar-title d-flex align-items-center px-3 py-4">
                            <h5 className="m-0 me-2" style={{paddingLeft: '10px'}}>
                                {props.t("_SIGN_UP_")} </h5>
                            <Link
                                to="#"
                                onClick={handleCloseForm}
                                className="right-bar-toggle ms-auto"
                            >
                                <i className="mdi mdi-close noti-icon"/>
                            </Link>
                        </div>

                        <hr className="my-0"/>

                        <div className="p-4">

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="form-group  input-sl">
                                                <div className="col-lg-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label
                                                            className='bolder-label'>{props.t("_USER_TYPE_")} </label>
                                                        <select
                                                            value={type}
                                                            onChange={(e) => setType(parseInt(e.target.value))}
                                                            className="form-control form-control-border">
                                                            <option
                                                                value="1">{props.t("_INDIVIDUAL_ACCOUNT_")} </option>
                                                            <option
                                                                value="2">{props.t("_ORGANIZATION_ACCOUNT_")} </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={12}>
                                    <div className="mb-3">
                                        {
                                            type === 2
                                                ?
                                                <div className="col-lg-12 col-sm-12">
                                                    <div className="form-group input-sl"

                                                    >
                                                        <div style={{float: 'left'}}>
                                                            <input
                                                                onClick={(e) => setCheckedIndMecarme(!checkedIndMecarme)}
                                                                defaultChecked={checkedIndMecarme}
                                                                type="checkbox"
                                                            />
                                                        </div>
                                                        <div

                                                            style={{
                                                                float: 'left',
                                                                marginTop: '-3px',
                                                                paddingLeft: "10px"
                                                            }}>
                                                            <label>
                                                                {props.t("_IND_MECARME_")}
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }

                                    </div>
                                </Col>
                                {} <Col md={12}>
                                <div className="mb-3">
                                    <div className="d-flex">
                                        <div className="square-switch">
                                            <input
                                                type="checkbox"
                                                id="square-switch2"
                                                switch="none"
                                                defaultChecked={foreignCitizen}
                                                onChange={() => {
                                                    setForeignCitizen(!foreignCitizen)
                                                }}
                                            />

                                            <label
                                                htmlFor="square-switch2"
                                                data-on-label="On"
                                                data-off-label="Off"
                                            />

                                        </div>
                                        <Label className="form-label" htmlFor="formrow-password-input">
                                            {props.t("_FOREIGN_CITIZEN_")}

                                        </Label>
                                    </div>
                                </div>
                            </Col>

                                {
                                    checkedIndMecarme
                                        ?
                                        <>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Dropzone
                                                        accept="image/*,application/pdf"
                                                        onDrop={acceptedFiles => {
                                                            handleAcceptedFiles(acceptedFiles)
                                                        }}
                                                    >
                                                        {({getRootProps, getInputProps}) => (
                                                            <div className="dropzone">
                                                                <div
                                                                    style={{
                                                                        fontSize: '17px'
                                                                    }}
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <p> {props.t("_UPLOAD_AN_EXRACT_FROM_THE_REGISTRY")}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    {
                                                        selectedFilesErr3
                                                            ? <span
                                                                style={{color: 'red'}}><i>  {props.t("_FILE_SIZE_SHOULD_NOT_EXCEED_3_MB")}</i></span>
                                                            : null
                                                    }
                                                    <div className="dropzone-previews mt-3" id="file-previews">
                                                        {selectedFiles.map((f, i) => {
                                                            return (
                                                                <Card
                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={i + "-file"}
                                                                >
                                                                    <div className="p-2">
                                                                        <Row className="align-items-center">
                                                                            {/* <Col className="col-auto">
                                                                                <img
                                                                                    data-dz-thumbnail=""
                                                                                    height="80"
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    alt={f.name}
                                                                                    src={f.preview}
                                                                                />
                                                                            </Col>*/}
                                                                            <Col>
                                                                                <Link
                                                                                    to="#"
                                                                                    className="text-muted font-weight-bold"
                                                                                >
                                                                                    {f.name}
                                                                                </Link>
                                                                                <p className="mb-0">
                                                                                    <strong>{f.formattedSize}</strong>
                                                                                </p>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Card>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                        : null
                                }
                                {
                                    selectedFilesErr
                                        ?
                                        <i style={{color: 'red'}}>
                                            ატვირთეთ ფაილი
                                        </i>
                                        : null
                                }


                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label className="form-label"
                                               htmlFor="formrow-email-input">{props.t("_EMAIL_")}</Label>
                                        <Input type="email"
                                               value={email}
                                               style={emailERR ? {borderColor: 'red'} : null}
                                               onChange={(e) => setEmail(e.target.value)}
                                               className="form-control" id="formrow-email-input"/>
                                        {
                                            emailERR ?
                                                <i className='errormessage'>{props.t("_ENTER_AN_EMAIL_EXAMPLE")} </i> : null
                                        }
                                    </div>

                                </Col>

                                <Col md={10}>
                                    <div className="mb-3">
                                        <Label className="form-label"
                                               htmlFor="formrow-password-input">{props.t("_PASSWORD_")}</Label>
                                        <Input type={eye ? 'password' : 'text'}
                                               value={password}
                                               style={passwordERR ? {borderColor: 'red'} : null}
                                               onChange={(e) => setPassword(e.target.value)}
                                               className="form-control" id="formrow-password-input"/>

                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div style={{paddingTop: '30px'}}
                                         onClick={handleEye}
                                    >
                                        <i className={eye ? 'uil-eye-slash' : 'uil-eye'}
                                           style={{fontSize: '20px', cursor: "pointer"}}></i>
                                    </div>
                                </Col>
                                <Col md={10}>
                                    <div className="mb-3">
                                        <Label className="form-label"
                                               htmlFor="formrow-rePassword-input"> {props.t("_CONFIRM_PASSWORD")} </Label>
                                        <Input type={eye2 ? 'password' : 'text'}
                                               value={rePassword}
                                               style={rePasswordERR ? {borderColor: 'red'} : null}
                                               onChange={(e) => setRePassword(e.target.value)}
                                               className="form-control" id="formrow-rePassword-input"/>

                                        {
                                            passwordERR || rePasswordERR ?
                                                <i className='errormessage'>{props.t("_PASSWORD_DOES_NOT_MATCH")}</i> : null
                                        }
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div style={{paddingTop: '30px'}}
                                         onClick={handleEye2}
                                    >
                                        <i className={eye2 ? 'uil-eye-slash' : 'uil-eye'}
                                           style={{fontSize: '20px', cursor: "pointer"}}></i>

                                    </div>

                                </Col>

                                {
                                    !foreignCitizen
                                        ?
                                        <>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label"
                                                           htmlFor="formrow-nameKa-input">{props.t("_FIRST_NAME_GEORGIAN_")} </Label>
                                                    {/*        <Input
                                                        value={nameKa}
                                                        style={nameKaERR ? {borderColor: 'red'} : null}
                                                        onChange={(e) => setNameKa(layoutFix(e.target.value))}
                                                        type="text" className="form-control" id="formrow-nameKa-input"/> */}

                                                    <Input
                                                        value={nameKa}
                                                        style={nameKaERR ? {borderColor: 'red'} : null}
                                                        onKeyPress={handleGeo}
                                                        name='nameKa'
                                                        onChange={(e) => setNameKa(e.target.value.replace(/[^ა-ჰ]+/g, ''))}
                                                        type="text" className="form-control" id="formrow-nameKa-input"/>
                                                </div>
                                            </Col>


                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label"
                                                           htmlFor="formrow-lastNameKa-input">{props.t("_LAST_NAME_GEORGIAN_")}</Label>
                                                    <Input type="text"
                                                           value={lastNameKa}
                                                           name='lastNameKa'
                                                           style={lastNameKaERR ? {borderColor: 'red'} : null}
                                                           onKeyPress={handleGeo}
                                                           onChange={(e) => setLastNameKa(e.target.value.replace(/[^ა-ჰ]+/g, ''))}
                                                           className="form-control" id="formrow-lastNameKa-input"/>
                                                </div>
                                            </Col>
                                        </>
                                        : null
                                }

                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label className="form-label"
                                               htmlFor="formrow-nameEn-input"> {props.t("_FIRST_NAME_ENGLISH_")}</Label>
                                        <Input type="text"
                                               value={nameEn}
                                               style={nameEnERR ? {borderColor: 'red'} : null}
                                               onChange={(e) => setNameEn(e.target.value.replace(/[^A-Za-z]+/g, ''))}
                                               className="form-control" id="formrow-nameEn-input"/>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label className="form-label"
                                               htmlFor="formrow-email-input"> {props.t("_LAST_NAME_ENGLISH_")} </Label>
                                        <Input type="text"
                                               value={lastNameEn}
                                               style={lastNameEnERR ? {borderColor: 'red'} : null}
                                            // onChange={(e) => setLastNameEn(e.target.value)} e.target.value.replace(/[^A-Za-z0-9\.]+/g, '')
                                               onChange={(e) => setLastNameEn(e.target.value.replace(/[^A-Za-z]+/g, ''))}
                                               className="form-control" id="formrow-email-input"/>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label className="form-label"
                                               htmlFor="formrow-city-input">{props.t("_CITY_")} </Label>
                                        <select value={city} style={cityERR ? {borderColor: 'red'} : null}
                                                onChange={(e) => setCity(e.target.value)}
                                                className="form-control form-control-border">
                                            <option value="">------</option>
                                            <option value="_TBILISI_">{props.t("_TBILISI_")}</option>
                                            <option value="_KUTAISI_">{props.t("_KUTAISI_")}</option>
                                            <option value="_BATUMI_">{props.t("_BATUMI_")}</option>
                                            <option value="_KOBULETI_">{props.t("_KOBULETI_")}</option>
                                            <option value="_ABASTUMANI_">{props.t("_ABASTUMANI_")}</option>
                                            <option value="_ABASHA_">{props.t("_ABASHA_")}</option>
                                            <option value="_ADIGENI_">{props.t("_ADIGENI_")}</option>
                                            <option value="_AMBROLAURI_">{props.t("_AMBROLAURI_")}</option>
                                            <option value="_ANAKLIA_">{props.t("_ANAKLIA_")}</option>
                                            <option value="_ASPINDZA_">{props.t("_ASPINDZA_")}</option>
                                            <option value="_AKHALGORI_">{props.t("_AKHALGORI_")}</option>
                                            <option value="_AKHALKALAKI_">{props.t("_AKHALKALAKI_")}</option>
                                            <option value="_AKHALTSIKHE_">{props.t("_AKHALTSIKHE_")}</option>
                                            <option value="_AKHMETA_">{props.t("_AKHMETA_")}</option>
                                            <option value="_BAKURIANI_">{props.t("_BAKURIANI_")}</option>
                                            <option value="_BAXMARO_">{props.t("_BAXMARO_")}</option>
                                            <option value="_BARDADI_">{props.t("_BARDADI_")}</option>
                                            <option value="_BOLNISI_">{props.t("_BOLNISI_")}</option>
                                            <option value="_BORJOMI_">{props.t("_BORJOMI_")}</option>
                                            <option value="_CAGVERI_">{props.t("_CAGVERI_")}</option>
                                            <option value="_GARDABANI_">{props.t("_GARDABANI_")}</option>
                                            <option value="_GONIO_">{props.t("_GONIO_")}</option>
                                            <option value="_GORI_">{props.t("_GORI_")}</option>
                                            <option value="_GRIGOLETI_">{props.t("_GRIGOLETI_")}</option>
                                            <option value="_GUDAURI_">{props.t("_GUDAURI_")}</option>
                                            <option value="_GURJAANI_">{props.t("_GURJAANI_")}</option>
                                            <option value="_DEDOFLISCKARO_">{props.t("_DEDOFLISCKARO_")}</option>
                                            <option value="_DMANISI_">{props.t("_DMANISI_")}</option>
                                            <option value="_DUSETI_">{props.t("_DUSETI_")}</option>
                                            <option value="_ZESTAFONI_">{props.t("_ZESTAFONI_")}</option>
                                            <option value="_ZUGDIDI_">{props.t("_ZUGDIDI_")}</option>
                                            <option value="_VANI_">{props.t("_VANI_")}</option>
                                            <option value="_TETRIWYARO_">{props.t("_TETRIWYARO_")}</option>
                                            <option value="_TELAVI_">{props.t("_TELAVI_")}</option>
                                            <option value="_TERJOLA_">{props.t("_TERJOLA_")}</option>
                                            <option value="_TIANETI_">{props.t("_TIANETI_")}</option>
                                            <option value="_KASPI_">{props.t("_KASPI_")}</option>
                                            <option value="_KAJRETI_">{props.t("_KAJRETI_")}</option>
                                            <option value="_KVARIATI_">{props.t("_KVARIATI_")}</option>
                                            <option value="_KOJORI_">{props.t("_KOJORI_")}</option>
                                            <option value="_LAGODEXI_">{props.t("_LAGODEXI_")}</option>
                                            <option value="_LANCXUTI_">{props.t("_LANCXUTI_")}</option>
                                            <option value="_MARNEULI_">{props.t("_MARNEULI_")}</option>
                                            <option value="_LENTEXI_">{props.t("_LENTEXI_")}</option>
                                            <option value="_MARTVILI_">{props.t("_MARTVILI_")}</option>
                                            <option value="_MCXETA_">{props.t("_MCXETA_")}</option>
                                            <option value="_MESTIA_">{props.t("_MESTIA_")}</option>
                                            <option value="_MCVANEKONCXI_">{props.t("_MCVANEKONCXI_")}</option>
                                            <option value="_NINOWMINDA_">{props.t("_NINOWMINDA_")}</option>
                                            <option value="_OZURGETI_">{props.t("_OZURGETI_")}</option>
                                            <option value="_ONI_">{props.t("_ONI_")}</option>
                                            <option value="_RUSTAVI_">{props.t("_RUSTAVI_")}</option>
                                            <option value="_SAGAREJO_">{props.t("_SAGAREJO_")}</option>
                                            <option value="_SAGURAMO_">{props.t("_SAGURAMO_")}</option>
                                            <option value="_SAIRME_">{props.t("_SAIRME_")}</option>
                                            <option value="_SAMTREDIA_">{props.t("_SAMTREDIA_")}</option>
                                            <option value="_SARFI_">{props.t("_SARFI_")}</option>
                                            <option value="_SACXERE_">{props.t("_SACXERE_")}</option>
                                            <option value="_SENAKI_">{props.t("_SENAKI_")}</option>
                                            <option value="_SIRNARI_">{props.t("_SIRNARI_")}</option>
                                            <option value="_STEFANWMIN_">{props.t("_STEFANWMIN_")}</option>
                                            <option value="_SURAMI_">{props.t("_SURAMI_")}</option>
                                            <option value="_TABAXMELA_">{props.t("_TABAXMELA_")}</option>
                                            <option value="_TYIBULI_">{props.t("_TYIBULI_")}</option>
                                            <option value="_UREKI_">{props.t("_UREKI_")}</option>
                                            <option value="_FOTI_">{props.t("_FOTI_")}</option>
                                            <option value="_QARELI_">{props.t("_QARELI_")}</option>
                                            <option value="_QEDA_">{props.t("_QEDA_")}</option>
                                            <option value="_YVARELI_">{props.t("_YVARELI_")}</option>
                                            <option value="_SUAXEVI_">{props.t("_SUAXEVI_")}</option>
                                            <option value="_CHAQVI_">{props.t("_CHAQVI_")}</option>
                                            <option value="_COXATAURI_">{props.t("_COXATAURI_")}</option>
                                            <option value="_CAGERI_">{props.t("_CAGERI_")}</option>
                                            <option value="_CXOROWYU_">{props.t("_CXOROWYU_")}</option>
                                            <option value="_WAVKISI_">{props.t("_WAVKISI_")}</option>
                                            <option value="_WALENJIXA_">{props.t("_WALENJIXA_")}</option>
                                            <option value="_WALKA_">{props.t("_WALKA_")}</option>
                                            <option value="_TSEROVANI_">{props.t("_TSEROVANI_")}</option>
                                            <option value="_WYALTUBO_">{props.t("_WYALTUBO_")}</option>
                                            <option value="_WNORI_">{props.t("_WNORI_")}</option>
                                            <option value="_WYNETI_">{props.t("_WYNETI_")}</option>
                                            <option value="_WIATURA_">{props.t("_WIATURA_")}</option>
                                            <option value="_XARAGAULI_">{props.t("_XARAGAULI_")}</option>
                                            <option value="_XASHURI_">{props.t("_XASHURI_")}</option>
                                            <option value="_XELVACAURI_">{props.t("_XELVACAURI_")}</option>
                                            <option value="_XOBI_">{props.t("_XOBI_")}</option>
                                            <option value="_XONI_">{props.t("_XONI_")}</option>
                                            <option value="_XULO_">{props.t("_XULO_")}</option>
                                        </select>

                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label className="form-label"
                                               htmlFor="formrow-city-input">{props.t('_Choose_a_branch')} </Label>
                                        <select value={filial}
                                                onChange={(e) => setCity(e.target.value)}
                                                className="form-control form-control-border">
                                            <option value="didube">{props.t('_Branch_Address_1')}</option>
                                            <option value="gldani">{props.t('_Branch_Address_2')}</option>
                                            <option value="isani">{props.t('_Branch_Address_3')}</option>
                                            <option value="kutaisi_1">{props.t('kutaisi_1')}</option>
                                        </select>

                                    </div>
                                </Col>

                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label className="form-label"
                                               htmlFor="formrow-address-input">{props.t("_ADDRESS_")}</Label>
                                        <Input type="text"
                                               value={address}
                                               style={addressERR ? {borderColor: 'red'} : null}
                                               onChange={(e) => setAddress(e.target.value)}
                                               className="form-control" id="formrow-address-input"/>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3">
                                        <Label className="form-label"
                                               htmlFor="formrow-phone-input">{props.t("_PHONE_NUMBER_")}</Label>
                                        <Input type="text"
                                               value={phone}
                                               style={phoneERR ? {borderColor: 'red'} : null}
                                               onChange={(e) => setPhone(e.target.value.replace(/\D/, ''))}
                                               maxLength="9"
                                               className="form-control" id="formrow-phone-input"/>
                                        {
                                            phoneERR ?
                                                <i className='errormessage'> {props.t("_PHONE_ERROR_")}</i> : null
                                        }
                                    </div>
                                </Col>

                                {
                                    type === 1 || checkedIndMecarme
                                        ?
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label className="form-label"
                                                       htmlFor="formrow-personId-input"> {props.t("_ID_NUMBER_")} </Label>
                                                <Input type="text"
                                                       value={personId}
                                                       maxLength="11"
                                                       style={personIdERR ? {borderColor: 'red'} : null}
                                                       onChange={(e) => setPersonId(e.target.value.replace(/\D/, ''))}
                                                       className="form-control" id="formrow-personId-input"/>
                                                {
                                                    personIdERR ?
                                                        <i className='errormessage'>{props.t("_ID_ERROR_")}</i> : null
                                                }

                                            </div>

                                        </Col>
                                        :
                                        <>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <h4 style={{color: '#d9534f'}}>{props.t("_ONLY_FOR_LTD")}</h4>
                                                </div>
                                            </Col>
                                            < Col md={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label"
                                                           htmlFor="formrow-orgName-input"> {props.t("_ORGANIZATION_NAME_")} {props.t("_GEORGIANS_NAME_")}</Label>
                                                    <Input type="text"
                                                           value={orgName}
                                                           name={'orgName'}
                                                           onKeyPress={handleGeo}
                                                           style={orgNameERR ? {borderColor: 'red'} : null}
                                                           onChange={(e) => setOrgName(e.target.value.replace(/[^ა-ჰ ]+/g, ''))}
                                                           className="form-control" id="formrow-orgName-input"/>
                                                </div>
                                            </Col>
                                            < Col md={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label"
                                                           htmlFor="formrow-orgName-input">   {props.t("_ORGANIZATION_NAME_")} {props.t("_ENGLISH_NAME_")}</Label>
                                                    <Input type="text"
                                                           value={orgNameEn}
                                                           style={orgNameEnERR ? {borderColor: 'red'} : null}
                                                           onChange={(e) => setOrgNameEn(e.target.value)}
                                                           className="form-control" id="formrow-orgName-input"/>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label"
                                                           htmlFor="formrow-orgId-input">{props.t("_LTD_LLC_ID_NUMBER_")}</Label>
                                                    <Input type="text"
                                                           value={orgId}
                                                           style={orgIdERR ? {borderColor: 'red'} : null}
                                                           onChange={(e) => setOrgId(e.target.value.replace(/\D/, ''))}
                                                           maxLength="9"
                                                           className="form-control" id="formrow-orgId-input"/>
                                                </div>
                                            </Col>
                                        </>
                                }

                                <Col md={12}>

                                    <div className="mb-3">

                                        <div className="form-check">
                                            <input className="form-check-input"
                                                   defaultChecked={checked}
                                                   onChange={() => {
                                                       setChecked(!checked)
                                                   }}
                                                   type="checkbox" id="floatingCheck"/>
                                            <label className="form-check-label" htmlFor="floatingCheck">
                                                {props.t("_TERMS_")}
                                                {/*<Link to='/terms'>წესებს</Link>*/}
                                                <button
                                                    onClick={() => {
                                                        tog_standard()
                                                    }}
                                                    className="btn btn-warning termsBtn" type="button"
                                                    id="termsClick"> {props.t("_TERMS_2_")}
                                                </button>
                                            </label>
                                        </div>

                                    </div>
                                    {
                                        checkedERR
                                            ?
                                            <i style={{color: 'red'}}>
                                                დაადასტურე
                                            </i>
                                            : null
                                    }
                                </Col>

                            </Row>

                            <button type="button"
                                    onClick={handleRegistration}
                                    className="btn btn-primary btn-block mt-3">
                                {props.t("_SIGN_UP_")}
                            </button>
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay"/>

            <Modal
                size="lg"
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard()
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("_SITE_TERMS_AND_CONDITIONS_")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <div dangerouslySetInnerHTML={{__html: props.Pages.page[ucFirstDescription]}}/>

                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard()
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        დახურვა
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    );
};


const mapStateToProps = state => {
    const {
        Layout,
        Pages
    } = state
    return {
        Layout,
        Pages
    }
};

export default withRouter(
    connect(mapStateToProps)(withTranslation()(RightSidebar))
)

