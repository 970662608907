import React from "react";
import {Card, CardBody, CardImg, CardText, Col, Container, Row} from "reactstrap";
import GoogleMapReact from 'google-map-react';
import {GoogleApiWrapper} from 'google-maps-react';
import {withTranslation} from "react-i18next";
import img2 from "../../assets/images/small/img-1.jpg";
import { FaMapMarkerAlt } from "react-icons/fa";
const AnyReactComponent = ({ text }) => <div style={{fontSize:'34px', color :'#f9004d'}}>{text}</div>;
    const center = {
        lat: 41.72502,
        lng: 44.78839
    }
const ContactPage = (props) => {

    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5 three-rem">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Row className="g-0 ">
                                    <Col md={4} style={{marginTop:'15px', padding :'10px'}}>
                                        <CardImg className="img-fluid" src={img2} alt="Card image cap"/>
                                    </Col>
                                    <Col md={8}>
                                        <CardBody>
                                            <Row>
                                                <Col md={12}>
                                                    <h3>{props.t("_WORKING_HOURS_")} </h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    {props.t("_MONDAY_FRIDAY_")}:
                                                </Col>
                                                <Col md={8}>
                                                    11:00-19:00
                                                </Col>
                                                <Col md={4}>
                                                    {props.t("_SATURDAY_")}:
                                                </Col>
                                                <Col md={8}>
                                                    11:00-15:00
                                                </Col>
                                            </Row>

                                            <hr/>
                                            <Row>
                                                <Col>
                                                    <h3>{props.t("_ADDRESS_")} </h3>
                                                </Col>
                                                <Col className='contact-icons-mob' style={{fontSize: '32px'}}>
                                                    <a href='https://www.facebook.com/people/AliGeorgia-%E1%83%90%E1%83%9A%E1%83%98%E1%83%AF%E1%83%9D%E1%83%A0%E1%83%AF%E1%83%98%E1%83%90/61550761802267/'
                                                       style={{color :'#495057'}}
                                                       target='_blank'> <i className="bx bxl-facebook-circle"></i> </a>

                                                    <a href='https://www.instagram.com/aligeorgia.ge/'
                                                       style={{color :'#495057'}}
                                                       target='_blank'> <i className="bx bxl-instagram-alt"></i> </a>

                                                    <a href='https://www.linkedin.com/company/aligeorgia/about/'
                                                       style={{color :'#495057'}}
                                                       target='_blank'> <i className="bx bxl-linkedin-square"></i> </a>
                                                </Col>
                                            </Row>

                                            <p style={{marginBottom:'5px'}}>
                                                <i className="uil-map-marker-shield"></i> {'  '}
                                                {props.t("_TBILISI_CITY_ARCHIL_KURDIANI_STREET_12_14_")}</p>
                                            <p style={{marginBottom:'5px'}}>
                                                <i className="uil-map-marker-shield"></i> {'  '}
                                                {props.t("_TBILISI_CITY_RAMAZ_SHENGELIA_")}</p>
                                            <p style={{marginBottom:'5px'}}>
                                                <i className="uil-map-marker-shield"></i> {'  '}
                                                {props.t("_TBILISI_CITY_QETEVAN")}</p>

                                            <p style={{marginBottom:'5px'}}>
                                                <i className="uil-map-marker-shield"></i> {'  '}
                                                {props.t("_KUTAISI_CITY_LADO")}</p>
                                            <p style={{marginBottom:'5px'}}>
                                                <i className="uil-envelope-alt"></i> {'  '}
                                                info.aligeorgia@gmail.com
                                            </p>
                                            <p style={{marginBottom:'5px'}}>
                                                <i className="uil-calling"></i> {'  '}
                                                (+995) 032 2 196 191
                                            </p>
                                            <Row className='contact-icons-desktop'>
                                                <Col   style={{fontSize: '32px'}}>
                                                    <a href='https://www.facebook.com/people/AliGeorgia-%E1%83%90%E1%83%9A%E1%83%98%E1%83%AF%E1%83%9D%E1%83%A0%E1%83%AF%E1%83%98%E1%83%90/61550761802267/'
                                                       style={{color :'#495057'}}
                                                       target='_blank'> <i className="bx bxl-facebook-circle"></i> </a> <i
                                                    className="bx bxl-instagram-alt"></i> <i
                                                    className="bx bxl-linkedin-square"></i>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <div style={{ height: '480px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={'AIzaSyCj9LRF5G_mwv4kgorDvOextXDk1QJamCU'}
                                defaultCenter={center}
                                defaultZoom={12}
                            >

                                <AnyReactComponent
                                    lat={41.72502}
                                    lng={44.78839}
                                    text={ <FaMapMarkerAlt/>}
                                />
                                <AnyReactComponent
                                    lat={41.78826}
                                    lng={44.81739}
                                    text={<FaMapMarkerAlt />}
                                />
                                <AnyReactComponent
                                    lat={41.68747}
                                    lng={44.82979}
                                    text={<FaMapMarkerAlt />}
                                />
                                <AnyReactComponent
                                    lat={42.25451}
                                    lng={42.69633}
                                    text={<FaMapMarkerAlt />}
                                />
                            </GoogleMapReact>

                        </div>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )

}
export default GoogleApiWrapper({
    apiKey: ('AIzaSyCj9LRF5G_mwv4kgorDvOextXDk1QJamCU')
})(withTranslation()(ContactPage))
